.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    font-family:"Trirong";
    background-image: url('./header.png');
    overflow: auto
}
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #7EAEDF;
    color: #000;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }

.scroll-to-top.visible {
opacity: 1;
}
